@use 'styles/base/mixins';
@use 'styles/base/variables';

.button span {
  padding-top: 0.5rem;
}

.tooltip {
  position: absolute;
  color: variables.$gb_white;
  z-index: 4;
  transform: translateX(0.5rem);
  transition:
    opacity 300ms linear,
    transform 300ms linear;
  pointer-events: all;

  &.grey {
    border-color: variables.$gb_grey_800;
    background-color: variables.$gb_grey_800;
    padding: 0.25rem 0.5rem;
    color: variables.$gb_white;
    min-width: initial;
    height: auto;

    &:hover {
      border-color: variables.$gb_grey_800;
      background-color: variables.$gb_grey_800;
      color: variables.$gb_white;
    }

    span {
      min-width: initial;
      height: auto;
      padding-top: 0;
    }
  }

  &:hover {
    background-color: variables.$gb_grey_800;
  }

  &.black {
    background-color: variables.$gb_black;
    color: variables.$gb_white;

    &:hover {
      background-color: variables.$gb_black;
      color: variables.$gb_white;
    }

    &.right::before,
    &.bottom::before {
      border-color: transparent variables.$gb_black transparent transparent;
    }
  }

  &.big {
    padding: 0.4rem 2.2rem;
    font-size: 1rem;
  }

  &.right::before {
    content: '';
    display: block;
    width: 0px;
    height: 0px;
    top: 50%;
    position: absolute;
    left: -5px;
    transform: rotate(360deg) translateY(-50%);
    border-style: solid;
    border-width: 3px 5px 3px 0;
    border-color: transparent variables.$gb_grey_800 transparent transparent;
  }

  &.bottom::before {
    content: '';
    display: block;
    width: 0px;
    height: 0px;
    left: 50%;
    top: -5px;
    position: absolute;
    margin-left: -6px;
    transform: rotate(90deg) translateY(-50%);
    border-style: solid;
    border-width: 3px 5px 3px 0;
    border-color: transparent variables.$gb_grey_800 transparent transparent;
  }
}
