@use 'styles/base/mixins';
@use 'styles/base/variables';

.productSlider {
  position: relative;
  padding-top: 1.25rem;
  @include mixins.bullet-points-spacing();

  @include mixins.screen-sm {
    padding-top: 0;
  }

  &.white {
    background-color: variables.$gb_white;
  }

  &.grey {
    background-color: variables.$gb_grey_050;
  }

  .overlay {
    @include mixins.screen-sm {
      :global(.image--aspect-ratio) {
        display: none;
      }
    }

    @include mixins.screen-md {
      :global(.image--aspect-ratio) {
        display: block;
      }
    }
  }

  .buttonInfo {
    position: absolute;
    z-index: 11;
    padding: 1rem;
    top: 1.25rem;
    right: 1.25rem;

    @include mixins.screen-sm {
      top: 2.5rem;
      right: 2.5rem;
    }

    &:hover,
    &:active {
      background-color: transparent;
      border: none;
    }
    svg {
      fill: var(--gb-brand-primary);
      width: 2rem;

      @include mixins.screen-sm {
        width: 2.25rem;
      }
    }
  }
}

.absoluteRow,
.absolute {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;

  &.infront {
    top: 0;
  }
}

.relative {
  position: relative;
}

.absoluteRow,
.infront {
  > div {
    top: 2.5rem;

    @include mixins.screen-sm {
      top: 5rem;
    }
  }
}

.imageSlide {
  z-index: 5;
  position: relative;
  pointer-events: none;

  button {
    pointer-events: all;
  }

  img {
    width: 100%;
    display: block;
    height: 100%;
  }

  &.absolute {
    z-index: 6;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 2.75rem;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
}

.slideHandler {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  z-index: 10;

  .divider {
    height: 100%;
    background-color: variables.$gb_white;
    width: 2px;
    pointer-events: all;
  }

  .slideHandlerButtonWrapper {
    position: relative;
    left: 0;
    display: flex;
    align-items: center;
    height: 100%;
    width: 5.625rem;
    justify-content: center;

    button {
      pointer-events: all;
      background-color: variables.$gb_white;
      border: none;

      &:hover,
      &:active {
        background-color: variables.$gb_grey_075;
      }

      svg {
        fill: variables.$gb_black;
      }
    }
  }
}

.white {
  h2 {
    @include mixins.screen-sm {
      color: variables.$gb_white;
    }
  }
}

.black {
  h2 {
    color: variables.$gb_black;
  }
}

.slideTile {
  z-index: 4;

  a {
    margin-bottom: 0.625rem;
    pointer-events: all;
  }
}

.link {
  a {
    pointer-events: all;
    cursor: pointer;
  }

  @include mixins.screen-sm {
    z-index: 9;
  }
}
